import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import BusinessServiceSidebar from "../components/Repeating/BusinessServiceSidebar";
import BusinessServiceSlider from "../components/Repeating/BusinessServiceSlider";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Business Consulting Services | DC, MD & VA | DeBlanc"
        description="Get expert guidance and achieve greater success with business consulting services from the team at DeBlanc + Murphy. Serving the greater Washington, DC, area."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-20 pb-10 md:py-14">
        <div className="container">
          <div className="max-w-[800px]">
            <h1>Business Consulting Services</h1>
            <p className="mb-0 md:text-xl">
              We deliver services that keep your business on the right track in
              increasingly complex and rapidly changing markets. Serving the
              greater Washington, DC area.
            </p>
          </div>
        </div>
      </section>

      <section className="relative mb-4 h-[375px] md:mb-32 md:h-[504px]">
        <BgImage
          image={getImage(data.parallaxImage.childImageSharp.gatsbyImageData)}
          className="h-full w-full bg-fixed"
        ></BgImage>
        <div className="gradient-blend absolute bottom-0 h-28 w-full"></div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-20 md:grid-cols-12">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-16">
                <h2>Get Expert Guidance</h2>
                <p>
                  If you want an objective opinion from professionals who have
                  spent years helping businesses just like yours, you’ve come to
                  the right place. Achieve greater success when you tap into our knowledge of strategic planning, feasibility studies, information systems evaluation, outsourcing evaluations, succession planning, and mergers and acquisitions.
                </p>
              </div>

              <div className="mb-16">
                <h3>Our Business Consultation Services</h3>
                <p>
                  We help identify areas that are negatively affecting the
                  profitability and growth of your business, then develop
                  practical and sound solutions so you can make logical
                  decisions and avoid pitfalls. You get comprehensive, flexible
                  strategies that address your business's key issues.
                </p>
              </div>

              <div>
                <h3>Here’s What Else We Can Help You With</h3>
                <p>
                  Our team has a wealth of expertise that can help your business
                  with the following:
                </p>

                <h4>Business Entity Selection</h4>
                <p>
                  Choosing your business entity (i.e., sole proprietor, LLC, S
                  corporation, etc.) is a crucial decision that can have
                  longstanding tax implications—positive or negative. We can
                  help you evaluate the pros and cons of each entity type to
                  help you determine which one will be the most advantageous for
                  your business.
                </p>

                {/* <h4>Business Valuation</h4>
                <p>
                  The value of your business can come into play in many ways,
                  such as considering selling the business, buying a new one, or
                  even going through a personal situation, such as a difficult
                  divorce. We can provide you with an objective analysis of your
                  business and a professional valuation.
                </p> */}

                <h4>Fraud Prevention & Detection</h4>
                <p>
                  We use accounting and auditing skills to provide an analysis
                  of financial records for fraud and theft investigation. Our
                  damage measurement methods can determine whether illegal
                  accounting practices occurred and the extent of financial
                  loss.
                </p>

                <h4>Litigation Support Services: Expert Witness</h4>
                <p>
                  Our firm has frequently been called upon to serve as an expert
                  witness in cases involving disputes and/or litigation in
                  accounting and financial business matters. We work with both
                  our clients and others who have been referred to us by
                  attorneys. We also collaborate with courts, judges, and
                  attorneys in many accounting and business matters.
                </p>

                <h4>Sales and Use Tax Services</h4>
                <p>
                  Many of our clients collect and submit sales taxes in many
                  different localities, and we can help you compile and prepare
                  sales tax returns in an efficient and timely manner.
                </p>

                <h4>State and Local Tax Services</h4>
                <p>We have clients operating all over the country and across many local jurisdictions. Each jurisdiction has a complex web of compliance requirements that we can help you navigate.</p>

                <ButtonSolid modal="modal-contact" text="Get a Quote" />
              </div>
            </div>
            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <BusinessServiceSidebar activeService={3} />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <BusinessServiceSlider hideService={3} />
      <About paddingTop={true} />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    parallaxImage: file(
      relativePath: { eq: "2.5 Business Consulting/1.0 Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default Page;
